import React, { useState, useRef } from 'react';
import WhatsAppButton from './WhatsAppButton'; 
import '../styles/Promocion.css';

const Promocion = () => {
  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef(null);

  const handleImageClick = () => {
    setShowVideo(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <div className="promocion">
      <div className='promotion_title'>
        <h3>COLONNUS</h3>
      </div>
      <div className='promocion_text'>
        <p>Maximiza la eficiencia en tus viajes con</p>
        <p className='descuentos'>DESCUENTOS PREFERENCIALES</p>
        <p>de hasta un <strong className='promociones_color'>90% </strong>en hoteles, vuelos, alquileres de autos y</p>
        <p><strong className="promociones_color"><span className='Text_decoration'>SERVICIOS</span> EXCLUSIVOS</strong> para viajeros frecuentes.</p>
        <p>SIN cargos adicionales NI comisiones ocultas.</p>
      </div>
      <div className='Question'>
        {!showVideo && (
          <div className='image-container' onClick={handleImageClick}>
            <img 
              src="./Video.png" 
              alt="Imagen de Promoción" 
              className='Img_video' 
            />
            <img 
              src="./Symbol.svg" 
              alt="Botón de Play" 
              className='Play_button' 
            />
          </div>
        )}
        {showVideo && (
          <video 
            ref={videoRef} 
            src="./video1.mp4" 
            controls 
            className='Video_promocion'
          />
        )}
        <p>¿Listo para <strong className='Color_question'>SER</strong> parte de la MEJOR plataforma de <span className='Color_question'>viajes</span>, con las <span className='Color_question'>mejores ofertas</span>?</p>
        <WhatsAppButton 
          text="Hola, quiero ser miembro VIP" 
          buttonText={<button className="miembro-vip-button">¡SÍ!, QUIERO SER MIEMBRO VIP</button>} 
          className="miembro-vip-link" 
        />
      </div>
      <div className='Bakcgroung'>
      </div>
      <img src="./top.svg" alt="Top SVG" className='Top_1'/>
    </div>
  );
}

export default Promocion;
