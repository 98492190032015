import React from "react";
import "../styles/TarjetaBroker.css";

const TarjetaBroker = () => {
  return (
    <div className="tarjeta-broker-container">
      <p className="tarjeta-broker-subtitle">
        tenemos sistemas de crédito con empresas americanas - pregunta a tu
        broker
      </p>
    </div>
  );
};

export default TarjetaBroker;
