import React from 'react';
import '../styles/Informacion.css';

const Informacion = () => {
  return (
    <div className="informacion-container">
      <img src="./bottom.svg" alt="Top SVG" className='top-svg' />
      <div className="informacion-content">
        <h1>¡Estamos contigo las <span className="resaltado">24 horas</span> del día, los <span className="resaltado">365 días</span> del año!</h1>
        <div className='raya'/>
        <ul>
          <li>-<strong>Siempre</strong> podrás <span className="resaltado">contactarnos</span> por diferentes medios.</li>
          <li>-Contamos con un asesor <span className="resaltado">24/7</span> que nos puede ayudar a elegir el <strong>mejor servicio turístico</strong>.</li>
          <li>-Estaremos <strong>solucionando</strong> TODAS tus dudas e inquietudes en el momento en que <span className="resaltado">tú</span> lo necesites.</li>
        </ul>
      </div>
    </div>
  );
}

export default Informacion;
