import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import '../styles/Ventajas.css';

const Ventajas = () => {
  return (
    <div className="ventajas-container">
      <h2>VENTAJAS DE LA MEMBRESÍA ANUAL DE <span className="ventajas-colonnus">COLONNUS</span></h2>
      <div className="ventajas-content">
        <div className="ventajas-item">
          <img src="icon-check.svg" alt="Check" className="ventajas-icon" />
          <p>Accede a los mejores precios, con <span className="ventajas-resaltado">diferencias de hasta un 50%</span> en comparación con las plataformas más populares.</p>
        </div>
        <div className="ventajas-item">
          <img src="icon-check.svg" alt="Check" className="ventajas-icon" />
          <p>Nuestros miembros disfrutan de <span className="ventajas-resaltado">descuentos exclusivos y paquetes personalizados</span>, diseñados específicamente para ofrecer experiencias únicas que no están disponibles en ningún otro lugar.</p>
        </div>
        <div className="ventajas-item">
          <img src="icon-check.svg" alt="Check" className="ventajas-icon" />
          <p>Lo mejor de todo es que en <span className="ventajas-resaltado">COLONNUS</span>, no hay sorpresas ni comisiones ocultas. Una modesta cuota anual les otorga acceso completo a nuestros beneficios excepcionales, convirtiéndose en una inversión mínima en comparación con los <span className="ventajas-resaltado">ahorros y privilegios</span> que recibirán al unirse a nuestra membresía.</p>
        </div>
      </div>
      <WhatsAppButton 
        text="Hola, quiero ser miembro VIP" 
        buttonText={<button className="ventajas-button">¡QUIERO SER MIEMBRO VIP!</button>} 
        className="ventajas-button-link" 
      />
    </div>
  );
}

export default Ventajas;
