import React, { createContext, useState, useContext } from "react";

const UserContext = createContext(null);

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    nombre: "Carlos Fernandez",
    descripcion_colonnus: `¡Bienvenidos a la excelencia en viajes! Soy el mejor asesor en el uso de la más avanzada plataforma privada de viajes. Con mi experiencia y dedicación, te garantizo que descubrirás los mejores precios del mundo para tus aventuras.<br/>
En nuestra plataforma, no solo encuentras precios inigualables, sino que también te ofrecemos una garantía exclusiva: si hallas una mejor oferta en cualquier otra página, te devolvemos el 110% de la diferencia. Este compromiso refleja nuestra confianza en proporcionar el máximo valor y satisfacción a nuestros clientes.<br/>
Permíteme mostrarte cómo puedes acceder a ofertas extraordinarias en hoteles, resorts, vuelos, Cruceros, alquiler de autos y más, todo a través de una plataforma que ha revolucionado la manera de viajar. Confía en mi experiencia y en la solidez de nuestra tecnología para que cada viaje sea perfecto y al mejor precio.<br/>
Conmigo y nuestra plataforma de viajes, el mundo está a tu alcance, y tu satisfacción está garantizada al 110%.`,
    numero: "3057781450",
    indicativo: "1",
    correo: "info@colonnus.club",
    imagen:
      "https://content.app-sources.com/s/59984382634598608/uploads/Images/Portada_video_CV-4309922.png?format=webp",
    pais: "Estados Unidos",
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
