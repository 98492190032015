import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-left">
        <p>Thank you for visiting our website</p>
      </div>
      <div className="footer-center">
        <img src="./logo.png" alt="Colonnus Logo" className="footer-logo" />
      </div>
      <div className="footer-right">
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    </footer>
  );
}

export default Footer;
