import React from 'react';
import '../styles/Secciones.css';

const secciones = [
  {
    title: 'CRUCEROS',
    description: 'Tienes la opción de elegir entre cruceros oceánicos, fluviales, veleros yates.',
    image: 'cruceros.jpg',
    filterGradient: 'linear-gradient(to bottom, rgba(5, 68, 94, 0.2), rgba(24, 154, 180, 0.6) 60%)' 
  },
  {
    title: 'RESORTS',
    description: 'Puedes quedarte en los mejores RESORTS del mundo a precios espectaculares!',
    image: 'resorts.jpg',
    filterGradient: 'linear-gradient(to top, rgba(69, 41, 6, 0.6), rgba(110, 141, 0, 0.2) 60%)' 
  },
  {
    title: 'CASAS',
    description: 'También tienes la opción de reservar una CASA dependiendo de tus necesidades.',
    image: 'casas.jpg',
    filterGradient: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0))' 
  },
  {
    title: 'AUTOS',
    description: 'Podrás seleccionar el tipo de vehículo que te gustaría tener durante tu estancia!',
    image: 'autos.jpg',
    filterGradient: 'linear-gradient(200deg, rgba(60, 46, 4, 1), rgba(212, 241, 244, 0))' 
  },
  {
    title: 'VUELOS',
    description: 'Elige tus vuelos de ida y vuelta que más se ajusten a tu plan!',
    image: 'vuelos.jpg',
    filterGradient: 'linear-gradient(200deg, rgba(5, 68, 94, 0.6), rgba(0, 110, 141, 0.2))' 
  },
];

const Secciones = () => {
  return (
    <div className="secciones-container">
      {secciones.map((seccion, index) => (
        <div key={index} className="seccion" style={{ backgroundImage: `url(${seccion.image})` }}>
          <div className="filtro" style={{ background: seccion.filterGradient }}></div>
          <div className="seccion-content">
            <h2 className="seccion-title">{seccion.title}</h2>
            <p className="seccion-description">{seccion.description}</p>
          </div>
        </div>
      ))}
      <img src="./back_ri.svg" alt="curve" className='position_image'/>
      <div  className='text_image'>
        <p>... y mucho MÁS!</p>
      </div>
    </div>
  );
}

export default Secciones;
