import React from 'react';
import WhatsAppButton from './WhatsAppButton'; 
import '../styles/VIPMembresia.css';

const VIPMembresia = () => {
  return (
    <div className="vip-container">
      <img src="./top-texture.svg" alt="Textura Superior" className="top-texture" />
      <div className="vip-content">
        <p>y tú... ¿quieres ser miembro VIP de <span className="colonnus">COLONNUS</span>?</p>
        <WhatsAppButton 
          text="Hola, quiero ser miembro VIP" 
          buttonText={<button className="vip-button">¡Sí!, QUIERO SER MIEMBRO VIP</button>} 
          className="vip-button-link" 
        />
      </div>
    </div>
  );
}

export default VIPMembresia;
