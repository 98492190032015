import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import { useUser } from "../context/UserProvider";
import Promocion from "./Promocion";
import Conocenos from "./Conocenos";
import Informacion from "./Informacion";
import Beneficios from "./Beneficios";
import Ventajas from "./Ventajas";
import Opciones from "./Opciones";
import Secciones from "./Secciones";
import VIPMembresia from "./VIPMembresia";
import TarjetaBroker from "./TarjetaBroker";
import Footer from "./Footer";
import ExpertProfile from "./ExpertProfile";
import TestimonialVideo from "./TestimonialVideo";
const Wrapper = () => {
  const { usuario } = useParams();
  const { user, setUser } = useUser();

  useEffect(() => {
    const fetchUserData = async () => {
      console.log(usuario);
      try {
        const response = await fetch(
          `https://bombazoo.com/api/visa/clave/${usuario}`
        );
        const data = await response.json();
        if (data.ok) {
          const user = data.visa;
          console.log(user);
          setUser({
            ...user,
            usuario: user.clave,
            imagen: user.url_imagen,
          });
        } else {
          setUser({
            nombre: "Carlos Fernandez",
            descripcion_colonnus: `¡Bienvenidos a la excelencia en viajes! Soy el mejor asesor en el uso de la más avanzada plataforma privada de viajes. Con mi experiencia y dedicación, te garantizo que descubrirás los mejores precios del mundo para tus aventuras.<br/>
En nuestra plataforma, no solo encuentras precios inigualables, sino que también te ofrecemos una garantía exclusiva: si hallas una mejor oferta en cualquier otra página, te devolvemos el 110% de la diferencia. Este compromiso refleja nuestra confianza en proporcionar el máximo valor y satisfacción a nuestros clientes.<br/>
Permíteme mostrarte cómo puedes acceder a ofertas extraordinarias en hoteles, resorts, vuelos, Cruceros, alquiler de autos y más, todo a través de una plataforma que ha revolucionado la manera de viajar. Confía en mi experiencia y en la solidez de nuestra tecnología para que cada viaje sea perfecto y al mejor precio.<br/>
Conmigo y nuestra plataforma de viajes, el mundo está a tu alcance, y tu satisfacción está garantizada al 110%.`,
            numero: "3057781450",
            indicativo: "1",
            correo: "info@colonnus.club",
            imagen:
              "https://content.app-sources.com/s/59984382634598608/uploads/Images/Portada_video_CV-4309922.png?format=webp",
            pais: "Estados Unidos",
          });
        }
      } catch (error) {
        setUser({
          nombre: "Carlos Fernandez",
          descripcion_colonnus: `¡Bienvenidos a la excelencia en viajes! Soy el mejor asesor en el uso de la más avanzada plataforma privada de viajes. Con mi experiencia y dedicación, te garantizo que descubrirás los mejores precios del mundo para tus aventuras.<br/>
En nuestra plataforma, no solo encuentras precios inigualables, sino que también te ofrecemos una garantía exclusiva: si hallas una mejor oferta en cualquier otra página, te devolvemos el 110% de la diferencia. Este compromiso refleja nuestra confianza en proporcionar el máximo valor y satisfacción a nuestros clientes.<br/>
Permíteme mostrarte cómo puedes acceder a ofertas extraordinarias en hoteles, resorts, vuelos, Cruceros, alquiler de autos y más, todo a través de una plataforma que ha revolucionado la manera de viajar. Confía en mi experiencia y en la solidez de nuestra tecnología para que cada viaje sea perfecto y al mejor precio.<br/>
Conmigo y nuestra plataforma de viajes, el mundo está a tu alcance, y tu satisfacción está garantizada al 110%.`,
          numero: "3057781450",
          indicativo: "1",
          correo: "info@colonnus.club",
          imagen:
            "https://content.app-sources.com/s/59984382634598608/uploads/Images/Portada_video_CV-4309922.png?format=webp",
          pais: "Estados Unidos",
        });
        console.error("Error fetching user data:", error);
      }
    };

    if (!user || user.usuario !== usuario) {
      fetchUserData();
    }
  }, [usuario]);
  return (
    <div className="App">
      <Promocion />
      <Conocenos />
      <Informacion />
      <Beneficios />
      <Ventajas />
      <Opciones />
      <Secciones />
      <VIPMembresia />
      <TestimonialVideo />
      <ExpertProfile />
      <TarjetaBroker />
      <Footer />
    </div>
  );
};

export default Wrapper;
