import React from "react";
import "../styles/ExpertProfile.css";
import { useUser } from "../context/UserProvider";
const ExpertProfile = () => {
  const { user } = useUser();
  const descripcionConSaltos = user.descripcion_colonnus
    .split("<br/>")
    .map((line, index, array) => (
      <React.Fragment key={index}>
        {line}
        {index < array.length - 1 && (
          <>
            <br />
            <br />
          </>
        )}
      </React.Fragment>
    ));
  return (
    <div className="container">
      <div className="expert-profile-container">
        <div className="expert-image">
          <img src={user.imagen} alt="Imagen broker" />
        </div>
        <div className="expert-details">
          <h1>{user.nombre}</h1>
          <p>{descripcionConSaltos}</p>
          <div className="raya4"></div>
          <p>Email: {user.correo}</p>
          <p>
            WhatsApp: +{user.indicativo} {user.numero}
          </p>
          <p>Pais: {user.pais}</p>
        </div>
      </div>
    </div>
  );
};

export default ExpertProfile;
