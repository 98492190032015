import React from 'react';
import '../styles/Conocenos.css';

const Conocenos = () => {
  return (
    <div className="conocenos-wrapper">


      <div className="conocenos-container">
        <p>Conoce un poco más acerca de <span className="colonnus">  COLONNUS  </span> la <strong>plataforma ideal</strong> para <strong>aventureros</strong> alrededor del mundo:</p>
        <div className="conocenos-content">
          <div className="conocenos-item">
            <img src="icon1.svg" alt="Descuentos" />
            <p><strong className='Bold_Text'>Descuentos de hasta un 90% en comparación con las plataformas convencionales</strong> <br />
              No te pierdas la <strong>oportunidad de ahorrar</strong> inteligentemente y elevar tus experiencias de viaje a un nivel sin igual con <span className="">COLONNUS</span>.</p>
          </div>
          <div className="conocenos-item">
            <img src="icon2.svg" alt="Viajes de categoría" />
            <p><strong className='Bold_Text'>Experimenta viajes de categoría superior y optimiza tu presupuesto</strong><br />
              Date la oportunidad de analizar y visualizar todas las ventajas que tiene el pertenecer a este exclusivo programa, así que no lo postergues más y únete a la <strong>revolución de los viajes de élite</strong>!</p>
          </div>
          <div className="conocenos-item">
            <img src="icon3.svg" alt="Comunidad selecta" />
            <p><strong className='Bold_Text'>Se parte de una comunidad selecta con acceso a ofertas únicas y experiencias excepcionales</strong><br />
              Amplía tu círculo de beneficios: únete a <span className="">COLONNUS</span> para desbloquear tarifas preferenciales y servicios exclusivos en cada viaje.</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Conocenos;
