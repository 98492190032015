import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import "./App.css";

import Wrapper from "./components/Wrapper";
import { UserProvider } from "./context/UserProvider";

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Wrapper />} />
          <Route path="/:usuario" element={<Wrapper />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
