import React from "react";
import { useUser } from "../context/UserProvider";
const WhatsAppButton = ({ text, buttonText, className }) => {
  const { user } = useUser();
  const href = `https://api.whatsapp.com/send/?phone=${user.indicativo}${
    user.numero
  }&text=${encodeURIComponent(text)}&type=phone_number&app_absent=0`;

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      className={className}
    >
      {buttonText}
    </a>
  );
};

export default WhatsAppButton;
