import React from 'react';
import '../styles/Beneficios.css';

const Beneficios = () => {
  return (
    <div className="beneficios-container">
      <div className="beneficios-content">
        <div className='Beneficion_cont'>
        <h3>¡VIAJA CON LA AMPLIA GAMA DE BENEFICIOS ATRACTIVOS QUE SÓLO <span className="colonnus2">COLONNUS</span> TE OFRECE!</h3>
        <p>
          Con <strong >COLONNUS</strong>, no solo vas a <span className="resaltado2">ahorrar</span>, sino que también vas a acceder a un mundo lleno de <span className="resaltado2">privilegios</span>, <span className="resaltado2">confort</span> y <span className="resaltado2">servicios</span> hechos a tu medida. 
          <strong > COLONNUS</strong> es el compañero perfecto, no solo para los que buscan <span className="resaltado2">facilidades económicas</span>, sino también para aquellos que buscan <span className="resaltado2">calidad</span>, <span className="resaltado2">excelencia</span> y <span className="resaltado2">bienestar</span> en cada una de sus travesías ¡por todo el mundo!
        </p>
        </div>
        <p>Contamos con muchas características y beneficios, entre ellos...</p>
        <div className="beneficios-icons">
          <div className="beneficio-item">
            <img src="icon-hotel.svg" alt="Acceso privilegiado a hoteles de alta categoría" />
            <p>Acceso privilegiado a hoteles de alta categoría</p>
          </div>
          <div className="beneficio-item">
            <img src="icon-reserva.svg" alt="Reserva sin complicaciones ni dificultades" />
            <p>Reserva sin complicaciones ni dificultades</p>
          </div>
          <div className="beneficio-item">
            <img src="icon-servicios.svg" alt="Servicios personalizados para viajeros habituales" />
            <p>Servicios personalizados para viajeros habituales</p>
          </div>
          <div className="beneficio-item">
            <img src="icon-descuentos.svg" alt="Descuentos significativos en cada reserva" />
            <p>Descuentos significativos en cada reserva</p>
          </div>
        </div>
      </div>
      <img src="./back_curves.svg" alt="curves" className='Curves' />
    </div>
  );
}

export default Beneficios;
