import React from 'react';
import '../styles/Opciones.css';

const Opciones = () => {
  return (
    <div className="opciones-container">
      <img src="./curve.svg" alt="Curva" className="opciones-curve" />
      <div className="opciones-content">
        <p>
          <span className="opciones-highlight">Conoce</span> algunas de <span className="opciones-highlight">las opciones</span> que te brinda <span className="opciones-colonnus">COLONNUS</span>:
        </p>
      </div>
    </div>
  );
}

export default Opciones;
